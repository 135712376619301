.App {
  min-height: 100vh;
  text-align: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  background-color: #375082;
  color: #e6e5ee;
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
}

.App-header div {
  margin-bottom: 2vh;
}

.visible {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s, opacity 0.2s;
}

.hidden {
  margin: 0;
  padding: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
}

.main {
  text-align: center;
  align-items: center;
}

.input-area div {
  padding-left: 30px;
  margin-bottom: 5vh;
}

h4 {
  margin: 0;
}

p {
  margin: 1vh;
}

.url {
  color: #5CC2A4;
  font-size: calc(10px + 2.2vmin);
  font-weight: 500;
  text-decoration: none;
  opacity: 0.85;
  transition: 0.3s;
}

.example {
  font-size: calc(10px + 2vmin);
}

.example a {
  color: #bde4f7;
}

a:hover {
  opacity: 1;
}

button {
  width: 50%;
  height: 12vh;
  min-height: 50px;
  font-size: calc(10px + 2.5vmin);
  min-width: 300px;
  max-width: 600px;
  border: none;
  border-radius: 10px;
  color: #FFF;
  font-weight: 1000;
  background-color: #5CC2A4;
  transition: 0.5s;
  opacity: 0.9;
}

.send-test {
  margin-top: 1vh;
  height: 4vh;
  font-size: calc(10px + 1.7vmin);
}

#test-publish {
  background-color: #5CC2A4;
  font-size: calc(10px + 2.0vmin);
  height: 7vh;
  width: 20vw;
}

button:hover {
  opacity: 1;
}

button:active {
  opacity: 0.9;
}

.log {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
}

.log h3 {
  display: flex;
  margin: 0px;
}

.log-entry {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  transition: 0.4s;
  font-size: calc(10px + 1vmin);
  border-radius: 8px;
  background-color: #2E2F3E;
  opacity: 0.7;
  margin-bottom: 5px;
  width: 80vw;
  max-width: 800px;
  min-width: 400px;
}

.log-entry:hover {
  opacity: 0.9;
}

.interactive {
  min-width: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.guide {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.guide div {
  width: 95vw;
  max-width: 800px;

}

.guide p {
  text-align: left;
  font-size: calc(8px + 1.3vmin);
  font-weight: 500;
}

.guide pre {
  text-align: left;
  font-size: calc(8px + 1.3vmin);
  font-weight: 500;
}